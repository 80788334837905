import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  usePlaidLink,
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
} from "react-plaid-link";
import Button, { ButtonVariant } from "../Button";
import { useQuery } from "react-query";
import { PLAID_API_KEYS } from "../../services/types";
import { fetchPlaidLinkToken } from "../../services/benji";

interface IPlaidLink {
  className?: string;
  countryId?: number;
  handleExit?: (...args: any) => void;
  handleSuccess?: (...args: any) => void;
  isDisabled?: boolean;
  isOutlineButton?: boolean;
  label?: string;
  linkId?: string;
  token?: string;
}

const PlaidLink = ({
  className,
  countryId,
  handleExit,
  handleSuccess = () => null,
  isDisabled = false,
  isOutlineButton = false,
  label,
  linkId = "",
  token,
}: IPlaidLink) => {
  const [isLoading, setIsLoading] = useState(true);
  const [savedToken, setSavedToken] = useState(token);
  const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");

  useEffect(() => {
    if (countryId) {
      setIsLoading(false);
    }
  }, [countryId]);

  const { data: linkToken } = useQuery(
    `${PLAID_API_KEYS.CREATE_LINK_TOKEN}_${linkId}`,
    () => fetchPlaidLinkToken(countryId, token),
    {
      enabled: !!countryId && !token && !savedToken,
      onSuccess: (res) => {
        setIsLoading(false);
        setSavedToken(res);
      },
      select: (data) => data?.link_token,
    }
  );
  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const config: PlaidLinkOptions = {
    // oauthStateId: `${process.env.REACT_APP_BASE_URL}/oauth-page.html`,
    clientName: "Benji",
    linkCustomizationName: "default",
    onSuccess: async (
      public_token: string,
      metadata: PlaidLinkOnSuccessMetadata
    ) => {
      // regular link flow
      try {
        setIsLoading(false);
        handleSuccess && handleSuccess(public_token, metadata);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    onExit: async (
      error: null | PlaidLinkError,
      metadata: PlaidLinkOnExitMetadata
    ) => {
      setIsLoading(false);
      handleExit && handleExit(metadata);
    },
    token: linkToken || savedToken,

    //required for OAuth; if not using OAuth, set to null or omit:
    receivedRedirectUri: isOAuthRedirect ? window.location.href : undefined,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  return isOAuthRedirect ? (
    <></>
  ) : (
    <Button
      className={className}
      data-form-id="link-form-id-benji"
      disabled={isDisabled || (!linkToken && !token) || !ready}
      isLoading={!ready || isLoading}
      label={label}
      name="plaid-link-btn"
      onClick={() => open()}
      variant={
        isOutlineButton ? ButtonVariant.outlined : ButtonVariant.contained
      }
    />
  );
};

export default PlaidLink;
